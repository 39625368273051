export function splitSecondToTime(totalSec) {
  if (!isNaN(totalSec)) {
    let sec = Math.abs(totalSec);
    let minutes = Math.floor(sec / 60);
    let seconds = sec - minutes * 60;
    return {
      minutes,
      seconds,
      isNegative: totalSec < 0
    };
  } else {
    return { minutes: 0, seconds: 0, isNegative: false };
  }
}

export function mergeTimeToSecond({ minutes, seconds, isNegative }) {
  let sign = isNegative ? -1 : 1;
  return (minutes * 60 + seconds) * sign;
}

export function buildTimeString({ minutes, seconds, isNegative }) {
  let minStr = minutes > 9 || minutes < 0 ? minutes : `0${minutes}`;
  let secStr = seconds > 9 || seconds < 0 ? seconds : `0${seconds}`;
  let sign = isNegative ? "-" : "";
  return `${sign}${minStr}:${secStr}`;
}

export function createdDateGroupByFunc(caseObj) {
  // 日期格式：2021-06-04T15:13:03
  // 取前十个数字
  return caseObj.createdDate.slice(0, 10);
}

export const presetDateRangeSelects = [
  { text: "今天", value: "today", default: true },
  { text: "最近一周", value: "week", default: false },
  { text: "最近一个月", value: "month", default: false },
  { text: "最近一年", value: "year", default: false },
  { text: "全部", value: "all", default: false }
];

export function getStartEndDateFromPresetRange(presetDateRangeValue) {
  let startDate = new Date();
  let endDate = new Date();
  // startDate 设置到今天的零点
  startDate.setHours(0, 0, 0, 0);
  // 调整时差
  startDate.setMinutes(startDate.getMinutes() - startDate.getTimezoneOffset());
  endDate.setMinutes(endDate.getMinutes() - endDate.getTimezoneOffset());
  // 默认为 today
  if (presetDateRangeValue === "week") {
    startDate.setDate(startDate.getDate() - 7);
  } else if (presetDateRangeValue === "month") {
    startDate.setMonth(startDate.getMonth() - 1);
  } else if (presetDateRangeValue === "year") {
    startDate.setFullYear(startDate.getFullYear() - 1);
  } else if (presetDateRangeValue === "all") {
    startDate = null;
    endDate = null;
  }
  return {
    startDate: startDate ? startDate.toISOString() : null,
    endDate: endDate ? endDate.toISOString() : null
  };
}
