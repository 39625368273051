<template>
  <v-overlay v-model="value">
    <v-progress-circular indeterminate size="64"></v-progress-circular>
  </v-overlay>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      required: true
    }
  },

  model: {
    prop: "value",
    event: "change"
  },

  watch: {
    value(newVal) {
      this.$emit("change", newVal);
    }
  }
};
</script>
