<template>
  <div>
    <AppLoadingSpinner v-model="isLoading" />
    <!-- 在 await dialog 显示的时候，不显示题目 -->
    <v-card v-if="!isShowAwaitDialog" flat max-width="800px" class="mx-auto">
      <div class="tester-toolbar">
        <v-btn small depressed text color="primary" @click="isShowZdy = true">
          <v-icon class="mr-2">mdi-help-circle-outline</v-icon>
          指导语
        </v-btn>
        <v-btn
          small
          depressed
          text
          color="primary"
          @click="isShowSaveExitDialog = true"
        >
          <v-icon class="mr-2">mdi-content-save-edit-outline</v-icon>
          保存退出
        </v-btn>
        <v-btn
          small
          depressed
          text
          color="red"
          @click="isShowTerminateDialog = true"
        >
          <v-icon class="mr-2">mdi-text-box-remove</v-icon>
          取消测试
        </v-btn>
      </div>
      <div class="title-bar">
        <div class="lb-title-progress">
          <div class="lb-disp-title">
            {{ lbDetails.lbDispName }}
          </div>
          <div>
            <v-progress-linear
              color="primary"
              :value="answerProgress"
            ></v-progress-linear>
            <span class="text-caption">
              {{ answeredQuesCount }}/{{ totalQuesCount }}
            </span>
          </div>
        </div>
        <div class="timebox-progress">
          <v-progress-circular
            v-if="!!lbDetails.timeboxSeconds"
            class="text-body-2"
            :rotate="-90"
            :size="70"
            :width="6"
            :value="timeboxProgress"
            color="teal"
          >
            {{ timeBoxRemainingStr }}
          </v-progress-circular>
          <div v-else class="text-body-1 mr-6 teal--text">
            {{ timeBoxRemainingStr }}
          </div>
        </div>
      </div>
      <div class="mt-4">
        <v-scroll-x-transition>
          <v-card v-show="isShowQues" class="px-2 py-2" elevation="6">
            <v-card-title>
              <span>{{ currentQuesWithAnswer.quesTitle }}</span>
              <!-- <v-spacer></v-spacer>
              <v-btn icon :disabled="!getLocalChineseVoice()">
                <v-icon color="primary" @click="speakQuesContent()">
                  mdi-volume-high
                </v-icon>
              </v-btn> -->
            </v-card-title>
            <v-card-text
              class="d-flex flex-wrap align-center"
              :class="{ 'justify-space-around': isPictureQues }"
            >
              <img v-if="isPictureQues" :src="currentQuesWithAnswer.quesImg" />
              <v-radio-group
                class="mx-8 mt-8"
                :row="isPictureQues"
                v-model="currentQuesWithAnswer.answer"
                @change="saveOneQuesAnswer"
              >
                <v-radio
                  v-for="(opt, optIndex) in getTrimedOptList()"
                  :key="optIndex"
                  :value="optIndex"
                  :label="opt"
                  :disabled="isWidgetDisabled"
                  class="mb-8"
                  :ripple="false"
                >
                </v-radio>
              </v-radio-group>
            </v-card-text>
            <v-card-actions>
              <v-container>
                <v-row>
                  <v-col cols="6">
                    <v-btn
                      v-if="!isAnsweringFirstQues"
                      color="primary"
                      block
                      depressed
                      :disabled="isWidgetDisabled"
                      @click="moveToPrevQues"
                    >
                      上一题
                    </v-btn>
                  </v-col>
                  <v-col cols="6">
                    <v-btn
                      v-if="isReviewingAnswer && !isAnsweringLastQues"
                      color="primary"
                      block
                      depressed
                      :disabled="isWidgetDisabled"
                      @click="moveToNextQues()"
                    >
                      下一题
                    </v-btn>
                  </v-col>
                  <v-col cols="12">
                    <v-btn
                      v-if="isAllQuesAnswered"
                      color="success"
                      block
                      depressed
                      @click="isShowSubmitDialog = true"
                    >
                      提交
                    </v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-actions>
          </v-card>
        </v-scroll-x-transition>
      </div>
    </v-card>
    <AppDialog
      v-model="isShowAwaitDialog"
      persistent
      overlay-color="#ffffff"
      overlay-opacity="0.9"
      title="测量尚未开始"
      :show-cancel="false"
    >
      心理测量尚未开始，请等待主试人开启答题。
    </AppDialog>
    <AppDialog
      v-model="isShowZdy"
      persistent
      title="量表指导语"
      color="primary"
      action-text="我已知晓，开始答题"
      :show-cancel="false"
      @confirm="isShowZdy = false"
    >
      {{ lbDetails.zdy }}
    </AppDialog>
    <AppDialog
      v-model="isShowSubmitDialog"
      persistent
      size="small"
      title="确定要提交吗？"
      color="success"
      action-text="提交"
      :loading="isBtnLoading"
      @confirm="submitTestResults"
    ></AppDialog>
    <AppDialog
      v-model="isShowTerminateDialog"
      size="small"
      title="确定要取消本次测试吗？"
      color="error"
      action-text="取消测试"
      :loading="isBtnLoading"
      @confirm="terminateTest"
    >
      <p>取消后答题结果会被删除，无法撤销。</p>
      <p>如需要保存测试进度然后退出，请点击“保存退出”。</p>
    </AppDialog>
    <AppDialog
      v-model="isShowSaveExitDialog"
      size="small"
      title="确定要保存测试进度并关闭吗？"
      color="primary"
      action-text="保存并退出"
      @confirm="saveTestProgress"
    >
      <p>保存当前答题进度，并退出本次答题。再次登录时可以继续答题。</p>
      <p>如需放弃本次答题，可点击“取消测试”</p>
    </AppDialog>
    <AppDialog
      v-model="isShowTimeupDialog"
      persistent
      size="small"
      title="答题已超时！"
      color="primary"
      action-text="已知晓，继续答题"
      :show-cancel="false"
      @confirm="isShowTimeupDialog = false"
    >
      <p>您的答题时间已经用完！</p>
      <p>请咨询您的心理医师，是否继续答题。</p>
      <template v-slot:action-ex>
        <v-btn text color="red" @click="isShowTerminateDialog = true">
          取消本次测试
        </v-btn>
      </template>
    </AppDialog>
    <AppMessageBox title="发生错误" v-model="errorMsg" />
  </div>
</template>

<script>
import AppLoadingSpinner from "@/components/AppLoadingSpinner";
import AppMessageBox from "@/components/AppMessageBox";
import AppDialog from "@/components/AppDialog";
import _ from "lodash";
import { mapGetters, mapActions } from "vuex";
import {
  fetchLbDetailsFromGuid,
  fetchAllQuesWithAnsFromLbGuid,
  saveOneAnswer,
  startOneTest,
  completeOneTest,
  saveOneTest,
  terminateOneTest
} from "@/api/sca";
import { fetchCanStartTestDirectly } from "@/api/dept";
import { splitSecondToTime, buildTimeString } from "@/utils/dateTime";

export default {
  components: {
    AppLoadingSpinner,
    AppMessageBox,
    AppDialog
  },

  data() {
    return {
      isLoading: false,
      isKeyPressed: false,
      newCaseGuid: "",
      timeCost: 0,
      optionZeroKeyNum: 0,
      lbDetails: {
        lbDispName: "",
        zdy: ""
      },
      isShowZdy: false,
      isShowQues: false,
      quesListWithAnswer: [],
      answerBeforeRadioChanged: -1,
      currentQuesIndex: -1,
      answeredQuesCount: 0,
      // 防止控件连续点击导致跳转错误的情况
      isWidgetDisabled: false,
      // 防止 destoryed 中把已提交的存为 saved 状态
      isTestSubmitted: false,
      isTestTerminated: false,
      // dialog
      isShowAwaitDialog: false,
      isShowSubmitDialog: false,
      isShowSaveExitDialog: false,
      isShowTerminateDialog: false,
      isShowTimeupDialog: false,
      isAlreadyTimeup: false,
      isBtnLoading: false,
      testTimer: null,
      // speechSynth: window.speechSynthesis,
      errorMsg: ""
    };
  },

  computed: {
    ...mapGetters({
      userGuid: "sca/userGuid",
      deptGuid: "sca/deptGuid",
      groupGuid: "sca/groupGuid",
      personGuid: "sca/personGuid",
      currentLbGuid: "sca/currentLbGuid"
    }),
    totalQuesCount() {
      return this.quesListWithAnswer.length;
    },
    currentQuesWithAnswer() {
      if (this.totalQuesCount > this.currentQuesIndex) {
        return this.quesListWithAnswer[this.currentQuesIndex] || {};
      }
      return {};
    },
    timeBoxRemaining() {
      let sec = this.timeCost;
      if (this.lbDetails.timeboxSeconds) {
        let timeBox = Number(this.lbDetails.timeboxSeconds);
        if (timeBox >= this.timeCost) {
          sec = timeBox - this.timeCost;
        } else {
          sec = (this.timeCost - timeBox) * -1;
        }
      }
      return sec;
    },
    timeboxProgress() {
      if (this.lbDetails.timeboxSeconds) {
        return (
          (this.timeBoxRemaining / Number(this.lbDetails.timeboxSeconds)) * 100
        );
      }
      return 0;
    },
    timeBoxRemainingStr() {
      if (this.timeBoxRemaining) {
        let timeObj = splitSecondToTime(this.timeBoxRemaining);
        return buildTimeString(timeObj);
      }
      return "";
    },
    isPictureQues() {
      return !!this.currentQuesWithAnswer.quesImg;
    },
    answerProgress() {
      return (this.answeredQuesCount / this.totalQuesCount) * 100;
    },
    isReviewingAnswer() {
      // 例：回答第三题时，index是2，count是2
      return this.currentQuesIndex < this.answeredQuesCount;
    },
    isAnsweringFirstQues() {
      return this.answeredQuesCount < 1;
    },
    isAnsweringLastQues() {
      return this.currentQuesIndex >= this.totalQuesCount - 1;
    },
    isAllQuesAnswered() {
      return (
        this.totalQuesCount > 0 && this.answeredQuesCount >= this.totalQuesCount
      );
    }
  },

  methods: {
    ...mapActions({
      addToSubmittedCaseList: "sca/addToSubmittedCaseList",
      addToSavedCaseList: "sca/addToSavedCaseList",
      addToCancelledCaseList: "sca/addToCancelledCaseList",
      clearScaState: "sca/clearScaState"
    }),
    async getLbDetails() {
      try {
        this.lbDetails = await fetchLbDetailsFromGuid(this.currentLbGuid);
      } catch (err) {
        this.errorMsg = err.message;
      }
    },
    async getAllQuesListWithAnswer() {
      try {
        var res = await fetchAllQuesWithAnsFromLbGuid({
          userGuid: this.userGuid,
          groupGuid: this.groupGuid,
          lbGuid: this.currentLbGuid,
          personGuid: this.personGuid
        });
        this.newCaseGuid = res.caseGuid;
        this.timeCost = res.timeCost;
        this.optionZeroKeyNum = res.optionZeroKeyNum;
        this.quesListWithAnswer = res.quesAnsList.map(qa => {
          return {
            quesIndex: qa.quesIndex,
            quesTitle: qa.quesTitle,
            quesImg: qa.quesImg,
            optList: qa.optList,
            answer: Number(qa.answer)
          };
        });
      } catch (err) {
        this.errorMsg = err.message;
      }
    },
    async getCanStartTestDirectly() {
      try {
        let canStartTest = await fetchCanStartTestDirectly(this.deptGuid);
        this.isShowAwaitDialog = !canStartTest;
      } catch (err) {
        this.errorMsg = err.message;
      }
    },
    async startTheTest() {
      try {
        await startOneTest({
          caseGuid: this.newCaseGuid
        });
      } catch (err) {
        this.errorMsg = err.message;
      }
    },
    startTestTimer() {
      this.testTimer = setInterval(() => {
        if (!this.isShowZdy && !this.isShowTimeupDialog) {
          // 显示指导语和timeup提示的时候不计时
          this.timeCost++;
        }
        if (
          !this.isAlreadyTimeup &&
          !isNaN(this.lbDetails.timeboxSeconds) &&
          this.timeCost >= Number(this.lbDetails.timeboxSeconds)
        ) {
          this.isShowTimeupDialog = true;
          this.isAlreadyTimeup = true;
        }
      }, 1000);
    },
    clearTestTimer() {
      clearInterval(this.testTimer);
    },
    getTrimedOptList() {
      let optList = this.currentQuesWithAnswer.optList;
      if (optList && optList.length) {
        return optList.filter(opt => !!opt);
      }
      return [];
    },
    startAnswerQues(startQuesIndex) {
      this.answeredQuesCount = startQuesIndex;
      if (this.totalQuesCount === startQuesIndex) {
        // 已经做完了
        this.currentQuesIndex = startQuesIndex - 1;
      } else {
        this.currentQuesIndex = startQuesIndex;
      }
      this.answerBeforeRadioChanged = this.currentQuesWithAnswer.answer;
      this.isShowQues = true;
      this.isWidgetDisabled = false;
    },
    moveToPrevQues() {
      if (this.currentQuesIndex > 0) {
        this.isWidgetDisabled = true;
        // 动画过渡
        this.isShowQues = false;
        this.transitionToQues(() => {
          this.currentQuesIndex--;
          this.answerBeforeRadioChanged = this.currentQuesWithAnswer.answer;
          this.isShowQues = true;
          this.isWidgetDisabled = false;
        });
      }
    },
    moveToNextQues(callback) {
      if (this.currentQuesIndex < this.totalQuesCount) {
        this.isWidgetDisabled = true;
        // 动画过渡，最后一题不做过渡动画
        !this.isAnsweringLastQues && (this.isShowQues = false);
        this.transitionToQues(() => {
          // 最后一题不再增加index
          !this.isAnsweringLastQues && this.currentQuesIndex++;
          this.answerBeforeRadioChanged = this.currentQuesWithAnswer.answer;
          this.isShowQues = true;
          this.isWidgetDisabled = false;
          callback && callback();
        });
      }
    },
    transitionToQues(nextTickCb) {
      // 停止朗读
      // if (this.speechSynth.speaking) {
      //   this.speechSynth.cancel();
      // }
      setTimeout(() => {
        this.$nextTick(nextTickCb);
      }, 300);
    },
    // getLocalChineseVoice() {
    //   let voices = this.speechSynth.getVoices();
    //   if (voices && voices.length) {
    //     return voices.find(v => v.lang === "zh-CN" && v.localService);
    //   }
    //   return "";
    // },
    // speakQuesContent(onStartCallback, onEndCallback) {
    //   // 正在朗读
    //   if (this.speechSynth.speaking) {
    //     return;
    //   }
    //   let quesContent = `${
    //     this.currentQuesWithAnswer.quesTitle
    //   }。${this.currentQuesWithAnswer.optList.join("，")}`;
    //   let utter = new SpeechSynthesisUtterance();
    //   utter.lang = "zh-CN";
    //   utter.text = quesContent;
    //   utter.voice = this.getLocalChineseVoice();
    //   if (onStartCallback) {
    //     utter.onstart = onStartCallback;
    //   }
    //   if (onEndCallback) {
    //     utter.onend = onEndCallback;
    //   }
    //   this.speechSynth.speak(utter);
    // },
    // 只要change了就要保存
    async saveOneQuesAnswer(newAnswer) {
      try {
        this.isLoading = true;
        await saveOneAnswer({
          caseGuid: this.newCaseGuid,
          index: this.currentQuesWithAnswer.quesIndex,
          answer: `${newAnswer}`,
          timeCost: this.timeCost
        });
        if (!this.isReviewingAnswer) {
          this.moveToNextQues(() => {
            this.answeredQuesCount++;
          });
        }
      } catch (err) {
        this.errorMsg = `上传本题失败或网络超时，请重新作答，或等网络稳定后登录继续答题。${err.message}`;
        this.currentQuesWithAnswer.answer = this.answerBeforeRadioChanged;
      }
      this.isLoading = false;
    },
    async submitTestResults() {
      try {
        this.isBtnLoading = true;
        await completeOneTest({
          caseGuid: this.newCaseGuid,
          timeCost: this.timeCost
        });
        this.isTestSubmitted = true;
        // 提交成功后加入 submitted 的列表
        this.addToSubmittedCaseList({
          lbId: this.lbDetails.lbId,
          lbDispName: this.lbDetails.lbDispName,
          caseGuid: this.newCaseGuid
        });
        this.closeTester();
      } catch (err) {
        this.errorMsg = err.message;
      }
      this.isBtnLoading = false;
    },
    async saveTestProgress() {
      try {
        this.isBtnLoading = true;
        await saveOneTest({
          caseGuid: this.newCaseGuid,
          timeCost: this.timeCost
        });
        // 暂存测试进度后加入 saved 的列表
        this.addToSavedCaseList({
          lbId: this.lbDetails.lbId,
          lbDispName: this.lbDetails.lbDispName,
          caseGuid: this.newCaseGuid
        });
        this.closeTester();
      } catch (err) {
        this.errorMsg = err.message;
      }
      this.isBtnLoading = false;
    },
    async terminateTest() {
      try {
        this.isBtnLoading = true;
        await terminateOneTest({
          caseGuid: this.newCaseGuid
        });
        this.isTestTerminated = true;
        // 中止测试后加入 cancelled 的列表
        this.addToCancelledCaseList({
          lbId: this.lbDetails.lbId,
          lbDispName: this.lbDetails.lbDispName,
          caseGuid: this.newCaseGuid
        });
        this.closeTester();
      } catch (err) {
        this.errorMsg = err.message;
      }
      this.isBtnLoading = false;
    },
    closeTester() {
      // 返回量表列表界面
      this.$router.push({ name: "scastepper" });
    },
    async bindOptionKeys(e) {
      if (!this.isKeyPressed && !this.isWidgetDisabled) {
        this.isKeyPressed = true;
        // 最小选项为 1 时，最大选项为 length
        // 最小选项为 0 时，最大选项为 length - 1
        let keyRangeMin = Number(this.optionZeroKeyNum);
        let keyRangeMax = this.getTrimedOptList().length - 1 + keyRangeMin;
        let keyPressed = Number(e.key);
        if (
          !isNaN(keyPressed) &&
          keyPressed >= keyRangeMin &&
          keyPressed <= keyRangeMax
        ) {
          // 答案与按键的数字是不同的
          let answer = keyPressed - keyRangeMin;
          this.currentQuesWithAnswer.answer = answer;
          await this.saveOneQuesAnswer(answer);
          this.isKeyPressed = false;
        } else {
          this.isKeyPressed = false;
        }
      }
    }
  },

  mounted() {
    // 退出前的提示
    window.onbeforeunload = function(e) {
      // 兼容IE8和Firefox 4之前的版本
      if (e) {
        e.returnValue = "确定要关闭答题窗口吗？";
      }
      // Chrome, Safari, Firefox 4+, Opera 12+ , IE 9+
      return "确定要关闭答题窗口吗？";
    };
  },

  async created() {
    this.isLoading = true;
    await this.getLbDetails();
    await this.getAllQuesListWithAnswer();
    await this.getCanStartTestDirectly();
    let lastAnsIndex = _.findLastIndex(
      this.quesListWithAnswer,
      qa => qa.answer !== -1
    );
    if (lastAnsIndex > -1) {
      // 中途刷新过了
      this.answeredQuesCount = lastAnsIndex + 1;
      this.startAnswerQues(lastAnsIndex + 1);
    } else {
      // 从头开始答题
      this.answeredQuesCount = 0;
      this.startAnswerQues(0);
    }
    // 如果答题尚未开始，则通过轮询的方式获取最新状态
    let awaitInterval = setInterval(() => {
      if (this.isShowAwaitDialog) {
        this.getCanStartTestDirectly();
      } else {
        clearInterval(awaitInterval);
        this.startTheTest();
        this.isLoading = false;
        this.isShowZdy = true;
        this.startTestTimer();
        document.onkeyup = this.bindOptionKeys;
      }
    }, 1000);
  },

  async destroyed() {
    if (!this.isTestSubmitted && !this.isTestTerminated) {
      // 未提交且未中止的情况下，关闭前自动保存
      await saveOneTest({
        caseGuid: this.newCaseGuid,
        timeCost: this.timeCost
      });
    }
    this.clearTestTimer();
    window.onbeforeunload = null;
  }
};
</script>

<style lang="scss" scoped>
.tester-toolbar {
  text-align: right;
}
.title-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .lb-title-progress {
    width: 87%;
    margin: 0 15px 0 5px;
    .lb-disp-title {
      margin: 10px 0 5px 0;
      font-size: 22px;
    }
  }
  .timebox-progress {
    padding: 15px 0 0 10px;
  }
}
</style>
