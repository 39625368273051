<template>
  <div>
    <v-dialog
      :value="!!messageInside"
      max-width="400px"
      @click:outside="messageInside = ''"
    >
      <v-card>
        <v-card-title>{{ title }}</v-card-title>
        <v-card-text>{{ messageInside }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="messageInside = ''">关闭</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String
    },
    message: {
      type: String,
      default: ""
    }
  },

  data() {
    return {
      messageInside: this.message
    };
  },

  model: {
    prop: "message",
    event: "change"
  },

  watch: {
    message(newVal) {
      this.messageInside = newVal;
    },
    messageInside(newVal) {
      newVal !== this.message && this.$emit("change", newVal);
    }
  }
};
</script>
